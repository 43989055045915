import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade, Autoplay, Navigation } from 'swiper/modules';
import Carousel01 from './Carousel01';
import Carousel02 from './Carousel02';
import Carousel03 from './Carousel03';

import 'animate.css';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';


/**
 * Introduction komponens
 * 
 * A Introduction komponens egy React komponens, amely egy carousel-t jelenít 
 * meg a Swiper könyvtár felhasználásával. 
 * Importálja a szükséges modulokat a Swiper-ből, valamint három különálló 
 * carousel komponenst (Carousel01, Carousel02, Carousel03).
 *
 * @returns {JSX.Element} - Introduction komponens
 */
function Introduction() {
  return (
    <Swiper
      spaceBetween={30}
      effect="fade"
      loop
      autoplay={{
        delay: 6500,
        disableOnInteraction: false,
      }}
      speed={3000}
      modules={[Autoplay, EffectFade, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <Carousel01 />
      </SwiperSlide>
      <SwiperSlide>
        <Carousel02 />
      </SwiperSlide>
      <SwiperSlide>
        <Carousel03 />
      </SwiperSlide> 
    </Swiper>
  );
}

export default Introduction

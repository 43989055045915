const GalleryList = [
    {
        id: 1,
        url: require('../../images/ranout/01.jpg'),
        name: "Kis piros szívbox",
        width: 1000,
        height: 750
    },
    {
        id: 2,
        url: require('../../images/ranout/02.jpg'),
        name: "Almazöld kiskapó",
        width: 750,
        height: 1000
    },
    {
        id: 3,
        url: require('../../images/ranout/03.jpg'),
        name: "Lila-barack kiskerámia",
        width: 1000,
        height: 750
    },
    {
        id: 4,
        url: require('../../images/ranout/04.jpg'),
        name: "Születésnapi csokor",
        width: 1000,
        height: 750
    },
    {
        id: 5,
        url: require('../../images/ranout/05.jpg'),
        name: "Barack-vintage körcsokor",
        width: 750,
        height: 1000
    },
    {
        id: 6,
        url: require('../../images/ranout/06.jpg'),
        name: "Kék-fehér ötvirágos rattanbox",
        width: 1000,
        height: 750
    },
    {
        id: 7,
        url: require('../../images/ranout/07.jpg'),
        name: "Mályva-ekrü ovális kaspós rózsa",
        width: 1000,
        height: 750
    },
    {
        id: 8,
        url: require('../../images/ranout/08.jpg'),
        name: "Pink-ekrü szívbox",
        width: 1000,
        height: 750
    },
    {
        id: 9,
        url: require('../../images/ranout/10.jpg'),
        name: "Szaténszívecskék",
        width: 1000,
        height: 750
    },
    {
        id: 10,
        url: require('../../images/ranout/11.jpg'),
        name: "Kék-fehér születésnapi szívbox desszerttel",
        width: 1000,
        height: 750
    },
    {
        id: 11,
        url: require('../../images/ranout/09.jpg'),
        name: "Színátmenetes lila nagy kaspós rózsa",
        width: 750,
        height: 1000
    },
    {
        id: 12,
        url: require('../../images/ranout/12.jpg'),
        name: "Vintage-ekrü rattanbox",
        width: 1000,
        height: 750
    },
    {
        id: 13,
        url: require('../../images/ranout/13.jpg'),
        name: "Sírcsokor sírkoszorúval",
        width: 750,
        height: 1000
    },
    {
        id: 14,
        url: require('../../images/ranout/14.jpg'),
        name: "Valentin napi piros nagy szívbox",
        width: 1000,
        height: 750
    },
    {
        id: 15,
        url: require('../../images/ranout/15.jpg'),
        name: "Lila-ekrü ötvirágos rattanbox",
        width: 1000,
        height: 750
    },
    {
        id: 16,
        url: require('../../images/ranout/16.jpg'),
        name: "Mályva-bordó florentin kosár",
        width: 1000,
        height: 750
    },
    {
        id: 17,
        url: require('../../images/ranout/18.jpg'),
        name: "Bordó sírcsokor",
        width: 1000,
        height: 750
    },
    {
        id: 18,
        url: require('../../images/ranout/17.jpg'),
        name: "Lila-barack-fehér körcsokor",
        width: 750,
        height: 1000
    },
    {
        id: 19,
        url: require('../../images/ranout/19.jpg'),
        name: "Lila-kék-fehér körcsokor",
        width: 1000,
        height: 750
    },
    {
        id: 20,
        url: require('../../images/ranout/20.jpg'),
        name: "Vintage-ekrü szívbox",
        width: 1000,
        height: 750
    },
    {
        id: 21,
        url: require('../../images/ranout/21.jpg'),
        name: "Rózsaszín kis kaspós rózsa",
        width: 750,
        height: 1000
    },
    {
        id: 22,
        url: require('../../images/ranout/22.jpg'),
        name: "Rózsaszín-padlizsán ovális kaspós rózsa",
        width: 1000,
        height: 750
    },
    {
        id: 23,
        url: require('../../images/ranout/23.jpg'),
        name: "Ötvirágos rattanboxok",
        width: 1000,
        height: 750
    },
    {
        id: 24,
        url: require('../../images/ranout/25.jpg'),
        name: "Rózsaszín nagy alakú szívbox",
        width: 1000,
        height: 750
    },
    {
        id: 25,
        url: require('../../images/ranout/26.jpg'),
        name: "Kék-fehér kis kosár",
        width: 750,
        height: 1000
    },
    {
        id: 26,
        url: require('../../images/ranout/28.jpg'),
        name: "Lila-rózsaszín ötvirágos rattanbox",
        width: 1000,
        height: 750
    },
    {
        id: 27,
        url: require('../../images/ranout/29.jpg'),
        name: "Piros-fehér ovális kaspós rózsa",
        width: 1000,
        height: 750
    },
    {
        id: 28,
        url: require('../../images/ranout/30.jpg'),
        name: "Piros-fehér születésnapi szívbox",
        width: 1000,
        height: 750
    },
    {
        id: 29,
        url: require('../../images/ranout/34.jpg'),
        name: "Lila-kék szívbox",
        width: 1000,
        height: 750
    },
    {
        id: 30,
        url: require('../../images/ranout/35.jpg'),
        name: "Bordó nagy kaspós rózsa",
        width: 1000,
        height: 750
    },
    {
        id: 31,
        url: require('../../images/ranout/31.jpg'),
        name: "Barackvirág-mályva ballagócsokor",
        width: 750,
        height: 1000
    },
    {
        id: 32,
        url: require('../../images/ranout/32.jpg'),
        name: "Mályva-vintage szívbox",
        width: 1000,
        height: 750
    },
    {
        id: 33,
        url: require('../../images/ranout/33.jpg'),
        name: "Piros-fehér-vintage körcsokor",
        width: 1000,
        height: 750
    },
    {
        id: 34,
        url: require('../../images/ranout/24.jpg'),
        name: "Piros kis szívbox",
        width: 1000,
        height: 750
    },
    {
        id: 35,
        url: require('../../images/ranout/27.jpg'),
        name: "Vintage-ekrü szívbox",
        width: 1000,
        height: 750
    },
    {
        id: 36,
        url: require('../../images/ranout/36.jpg'),
        name: "Piros-fehér-vintage körcsokor",
        width: 750,
        height: 1000
    },
    {
        id: 37,
        url: require('../../images/ranout/37.jpg'),
        name: "Színátmenetes sárga nagy kaspós rózsa",
        width: 750,
        height: 1000
    },
]
export default GalleryList
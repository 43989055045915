import React, { useState, useEffect } from "react";
import ProductsFilter from './ProductsFilter/ProductsFilter'
import axios from 'axios'
import { makeStyles } from '@mui/styles';
import usePagination from "./Pagination";
import { Box, Pagination } from "@mui/material";
import { useParams } from "react-router-dom";
import { LoadingGif } from "../LoadingGif";
import ProductsSchema from "./ProductsSchema";

/**
 * sortByColor komponens
 * 
 * A sortByColor egy React funkcionális komponens, amely Axios segítségével 
 * az eseményekre szűrt termékek adatait kér le egy API végpontról, amikor a 
 * komponens betöltődik. 
 * Ezután megjelenít egy betöltési gifet, amíg az adatokat lekéri, és a lekért 
 * adatokat a ProductsSchema komponens segítségével jeleníti meg. Tartalmaz egy
 * lapozó komponenst is, amellyel navigálhatunk a lekért adatok között. 
 * A komponens tartalmaz egy szűrő komponenst is ProductsFilter néven, amellyel
 * szűrhetjük a termékeket.
 *
 * @returns {JSX.Element} - sortByColor komponens
 */
function SortByEvent(params) {
    const [isFetchPending, setFetchPending] = useState(false);
    const [products, setProducts] = useState([])
    let [page, setPage] = useState(1);
    const perPage = 12;
    const count = Math.ceil(products.length / perPage);
    const data = usePagination(products, perPage);
    const handleChange = (e, p) => {
        setPage(p);
        data.jump(p);
    };
    const useStyles = makeStyles(() => ({
        ul: {
            "& .MuiPaginationItem-root": {
                backgroundColor: "#fff"
            }
        }
    }));
    const Event = useParams();
    const classes = useStyles();
    useEffect(() => {
        setFetchPending(true);
        axios.get(`https://rt-teszt-hu.hu/api/sortbyevent`, 
        { params: 
            {event: Event.productEvent,}
        } )
        .then(function (response) {
            setProducts(response.data);
            setFetchPending(false)
            
            })
            .catch(function (error) {
                console.log(error);
                
            })
            .finally(function () {
                setFetchPending(false)
            })
        }, [Event.productEvent]);
return (
    <nav className='col-12 col-xxl-10 products-nav m-0 p-0 shadow-lg'>
        <ProductsFilter />
        <div className='row py-2 me-0 mt-1 ms-1 products-background'>
            <div 
                className='col-12 row d-flex mx-0 justify-content-center'>
                <Box p="5" >
                    {/* Betöltő gif */}
                    {isFetchPending ? (
                        <LoadingGif />
                        ) : (<ProductsSchema products={data.currentData()} />)}
                    {/* Oldal lapozó*/}
                    <Box 
                        justifyContent={"center"} 
                        alignItems="center" 
                        display={"flex"}>
                        <Pagination
                            color="primary"
                            count={count}
                            size="large"
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChange}
                            boundaryCount={count}
                            classes={{ ul: classes.ul }}/>
                    </Box>
                </Box>
            </div>
        </div>        
    </nav>)
}

export default SortByEvent

import { useState } from "react";

/**
 * Pagination komponens
 * 
 * A usePagination egy egyéni React hook, amely az oldalazási funkcionalitást 
 * segíti elő. Egy adattömböt és az egy oldalon megjelenítendő elemek számát 
 * veszi bemenetként. Kezeli az aktuális oldal állapotát, kiszámítja a 
 * maximális oldalszámot az adatok hossza és az egy oldalon megjelenítendő 
 * elemek száma alapján, lehetőséget biztosít az aktuális oldalon 
 * megjelenítendő adatok lekérésére, és funkciókat kínál a következő oldalra, 
 * az előző oldalra való navigáláshoz, vagy egy adott oldalra való ugráshoz.
 *
 * @param {array} data
 * @param {number} itemsPerPage
 * @returns {{ next: () => number;
 *             prev: () => number; 
 *             jump: (page: number) => number;
 *             currentData: () => array; 
 *             currentPage: number; 
 *             maxPage: number; }}
 */
function usePagination(data, itemsPerPage) {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

export default usePagination;
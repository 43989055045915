import React, { useState } from 'react'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import logo from '../../../images/logo2.png'
import Radio from '@mui/material/Radio'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel  from '@mui/material/FormControlLabel'
import Tooltip from '@mui/material/Tooltip'
import { NavLink} from 'react-router-dom'
import { red, blue, purple, orange, pink,
        yellow, grey, amber} from '@mui/material/colors'

/**
 * Filters komponens
 * 
 * A Filters komponens egy React funkcionális komponens, amely egy terméklista 
 * szűrőit jeleníti meg. 
 * Tartalmaz szűrőket az "Alkalom" és "Színek" kategóriákhoz. 
 * A komponens a useState hook-ot használja az "Alkalom" szűrő kiválasztott 
 * értékének kezelésére. Emellett a react-router-dom könyvtárból importálja 
 * a NavLink komponenst, hogy hiperhivatkozásokat hozzon létre minden szűrő 
 * opcióhoz. A komponens egy logóképet, egy fejlécet és két szűrőkészletet 
 * jelenít meg: egyet az alkalmakra és egyet a színekre.
 * 
 * @date 2024. 01. 31. - 18:10:31
 * @returns {JSX.Element} - Filters komponens
 */
function Filters() {
  const [productEvent, setProductEvent] = useState('');

    /**
   * Kezeli a radio-buttons változási eseményét.
   * Frissíti az „Alkalom” szűrő kiválasztott értékét.
   * 
   * @param {React.ChangeEvent<HTMLInputElement>} event - A változás eseménye.
   */
  const handleChange = (event) => {
    setProductEvent(event.target.value);
  };
  return (
    <>
      <img className='img-fluid col-12 px-4' src={logo} alt="logo" />
      <h2 className='blue text-center py-2'>Szűrők:</h2>
        <Divider>
            <Chip variant="outlined" label="Alkalom" />
        </Divider>
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={productEvent}
                onChange={handleChange}
                sx={{ p: 2, textAlign: "left" }}
            >
          {occasionOptions.map((option) => (
            <NavLink to={`/sortByEvent/${option.value}`}>
              <FormControlLabel
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            </NavLink>
          ))}
            </RadioGroup>
        </FormControl>
        <Divider>
            <Chip variant="outlined" label="Színek" />
        </Divider>
        <div className='row p-3'>
          {colorOptions.map((colors) => (
            <div className='col-4'>
                <NavLink to={`/sortByColor/${colors.name}`}>
                  <div className='py-2 d-flex justify-content-center'>
                    <Tooltip title={colors.name} placement='left'>
                      <div className='col-3 color-style rounded-circle'
                          style={{ backgroundColor: colors.color }}>
                      </div>
                    </Tooltip>
                  </div>
                  </NavLink>
              </div>
          ))}
        </div>  
    </>
  );
}

// Alkalom lehetőségek
const occasionOptions = [
  { value: "birthday", label: "Szülinap" },
  { value: "nameday", label: "Névnap" },
  { value: "valentineday", label: "Valentin nap" },
  { value: "womensday", label: "Nők napja" },
  { value: "mothersday", label: "Anyák napja" },
  { value: "graduation", label: "Ballagás" },
  { value: "wedding", label: "Esküvő" },
  { value: "easter", label: "Húsvét" },
  { value: "allsoulsday", label: "Mindenszentek" },
  { value: "christmas", label: "Karácsony" },
];
// Szín lehetőségek
const colorOptions = [
  { color: amber[200], name: "Arany"},
  { color: grey[50], name: "Fehér"},
  { color: red['A700'], name: "Piros"},
  { color: purple[500], name: "Lila"},
  { color: blue[500], name: "Kék"},
  { color: yellow[100], name: "Ekrü"},
  { color: orange[300], name: "Barack"},
  { color: pink[100], name: "Rózsaszín"},
  { color: yellow['A200'], name: "Sárga"},
];
export default Filters

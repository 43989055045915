import React, { useState } from 'react'
import logo from '../../images/logo2.png'
import MuiTabs from './MuiTabs'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Drawer from '@mui/material/Drawer'
import Login from './Login'
import Registration from './Registration';
import Cart from './Cart';
import { useSelector } from 'react-redux'


function LinkTab(props) {
    return (
        <Tab
        component="a"
        onClick={(event) => {
            event.preventDefault();
        }}
        {...props}
        />
    );
};
/**
 * Nav komponens
 * 
 * A Nav függvény egy React komponens, amely egy navigációs sávot jelenít meg. 
 * Tartalmaz egy logót, egy mobil navigációhoz kapcsoló gombot és egy sor 
 * navigációs hivatkozást. Emellett tartalmaz egy MuiTabs komponenst.
 * 
 * @returns {JSX.Element} - Nav komponens
 * TODO
 */
function Nav() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const cartItems = useSelector((state) => state.cartItems);
    const cartSize = cartItems.length;
return (
    <nav 
        className="container-fluid main-nav navbar navbar-expand-lg 
                    sticky-top">
        <div className="container">
            <img 
                className="img-fluid navbar-title logoImage me-2"
                src={logo}
                alt="logo"
            />
            <div className='p-2 d-block d-lg-none'>
                <IconButton 
                    aria-label="cart"
                    edge="start"
                    onClick={() => setIsDrawerOpen(true)}
                    >  
                    <Badge badgeContent={cartSize} color="secondary">
                        <ShoppingCartIcon />
                    </Badge>
                    <Typography variant="subtitle1" px={2} component={'p'}>
                    </Typography>
                    </IconButton>
                    <Drawer
                        anchor="right"
                        marginLeft={0}
                        open={isDrawerOpen}
                        onClose={() => setIsDrawerOpen(false)}
                    >
                        <Box 
                            p={2}
                            marginRight={2}
                            marginLeft={0}
                            width="300px"
                            textAlign="center"
                            alignItems="center"
                            role="presentation"
                        >
                            <Cart />
                        </Box>
                    </Drawer>
            </div>
            <button 
                className="navbar-toggler" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#navbarNav" 
                aria-controls="navbarNav" 
                aria-expanded="false" 
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <MuiTabs />
                <div className="d-block d-lg-none">
                    <ul className="navbar-nav ">
                        <li className="nav-item">
                            <a 
                                className="nav-link text-center" 
                                aria-current="page" 
                                href="/#introduction">
                                BEMUTATKOZÁS
                            </a>
                        </li>
                        <li className="nav-item">
                            <a 
                                className="nav-link text-center" 
                                aria-current="page" 
                                href="/#skills">
                                TERMÉKEK
                            </a>
                        </li>
                        <li className="nav-item">
                            <a 
                                className="nav-link text-center" 
                                aria-current="page" 
                                href="/#experience">
                                VÁSÁRLÁS MENETE
                            </a>
                        </li>
                        <li className="nav-item">
                            <a 
                                className="nav-link text-center" 
                                aria-current="page" 
                                href="/#experience">
                                KAPCSOLAT
                            </a>
                        </li>
                        <li className="nav-item d-flex justify-content-center">
                        <Button
                            id="demo-positioned-button"
                            variant="outlined"
                            className="rounded-pill px-4 py-0"
                            color="secondary"
                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <AccountCircleIcon />
                            <LinkTab className="login" />
                        </Button>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                        >
                            <MenuItem>
                            <Login />
                            </MenuItem>
                            <MenuItem>
                            <Registration />
                            </MenuItem>
                        </Menu>
                        </li>
                    </ul>
                </div>
                </div>
            </div>
        
    </nav>
    );
}

export default Nav

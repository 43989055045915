import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Drawer from '@mui/material/Drawer'
import Login from './Login'
import Registration from './Registration';
import Cart from './Cart';
import { useSelector } from 'react-redux'



function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
};

/**
* MuiTabs komponens
*
* MuiTabs egy React komponens, amely egy navigációs sávot renderel fülekkel, 
* Material-UI komponenseket használva. Ez lehetővé teszi a felhasználó számára,
* hogy navigáljon egy weboldal vagy alkalmazás különböző szakaszai között. 
* A komponens továbbá funkcionalitást tartalmaz egy bevásárlókosár ikon 
* megjelenítéséhez, amely egy jelzéssel mutatja az elemek számát a kosárban, 
* valamint egy felhasználói fiók ikont, amely egy menüt nyit meg a 
* bejelentkezési és regisztrációs lehetőségekkel.
*
* @returns {JSX.Element} - MuiTabs komponens
* @validate
*/
export default function MuiTabs() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickScroll = (value) => {
    const element = document.getElementById(value);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const cartItems = useSelector((state) => state.cartItems);
  const cartSize = cartItems.length;

  return (
    <div className="collapse navbar-collapse justify-content-end">
      <div className="d-none d-lg-block ">
        <Box sx={{ width: '100%' }} mx="0">
          <Tabs 
            textColor="secondary"
            indicatorColor="secondary"
            value={value} 
            onChange={handleChange} 
            aria-label="secondary nav tabs"
            scrollButtons="auto"
            variant="scrollable"
          >
            <LinkTab 
              label="Bemutatkozás" 
              href="/#introduction"  
              onClick={() => handleClickScroll('introduction')} 
            />
            <LinkTab 
              label="Termékek" 
              href="/#skills" 
              onClick={() => handleClickScroll('skills')}
            />
            <LinkTab 
              label="Vásárlás menete" 
              href="/#experience" 
              onClick={() => handleClickScroll('experience')}
            />
            <LinkTab 
              label="Kapcsolat" 
              href="/#workplaces" 
              onClick={() => handleClickScroll('workplaces')} 
            />
                <IconButton 
                  aria-label="cart"
                  edge="start"
                  onClick={() => setIsDrawerOpen(true)}
                >
                  <Badge badgeContent={cartSize} color="secondary">
                    <ShoppingCartIcon />
                  </Badge>
                <Typography variant="subtitle1" px={2} component={'p'}>
                  KOSÁR
                </Typography>
                </IconButton>
              <Drawer
                anchor="right"
                marginLeft={0}
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
              >
                  <Box 
                    p={2}
                    marginRight={2}
                    marginLeft={0}
                    width="300px"
                    textAlign="center"
                    alignItems="center"
                    role="presentation"
                  >
                    <Cart />
                  </Box>
                </Drawer>
            <div>
              <Button
                id="demo-positioned-button"
                variant="outlined"
                className="rounded-pill px-4 py-0"
                color="secondary"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <AccountCircleIcon />
                <LinkTab className="login" />
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem>
                  <Login />
                </MenuItem>
                <MenuItem>
                  <Registration />
                </MenuItem>
              </Menu>
            </div>
          </Tabs>
        </Box>
      </div>
    </div>
  );
}
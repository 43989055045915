import React, { useState } from 'react'
import { Divider } from '@mui/material'
import GalleryImage from './../../images/gallery4.jpg';
import PhoneButton from './../../images/phone-button.png';
import EmailButton from './../../images/email-button.png';
import RanoutGallery from './RanoutGallery';

function GalleryNav() {
    const [isIntroductionShown, setIsIntroductionShown] = useState(true);

return (
    <div>
        { isIntroductionShown ? (
        <div className='gallery-introduction d-flex justify-content-center 
                        align-items-center p-4 row'>
            <div className='col-10 col-md-9 col-xl-5 col-xxl-5 mellow py-5 
                            px-auto text-center'>
                <h2 className='fw-bolder'>
                    Egyedi Örökvirág Ajándékok – Szatén Szalagból
                </h2>
                <p className='blue p-2 pt-4 fs-5 text'>
                    Tedd különlegessé az alkalmat személyre szabott örökvirág 
                    ajándékkal! Legyen szó személyre szabott színkombinációról,
                    designról vagy üzenetről, csapatunk készen áll, hogy valóra 
                    váltsa az elképzeléseidet.
                </p>
                <p className='blue p-2 fs-5 text'>
                    Tekintsd meg képgalériánkat az inspirációért, és vedd fel 
                    velünk a kapcsolatot emailen vagy telefonon, hogy 
                    megbeszélhessük, hogyan hozhatjuk létre együtt álmaid 
                    ajándékát.
                </p>
                <a 
                    className='col-9 mx-auto mellow-hover rounded' 
                    href="tel:+3630-572-62-69">
                    <img 
                        className='col-2 img-fluid p-2' 
                        src={PhoneButton} 
                        alt="Telefon gomb" />
                    <h3 className='col-8 d-flex align-items-center'>
                        +3630-951-49-59
                    </h3>
                </a>
                <Divider  sx={{ bgcolor: "secondary.light", mx: 12}} />
                <a 
                    className='col-9 mx-auto mellow-hover mb-3 rounded' 
                    href="#">
                    <img 
                        className='col-2 img-fluid p-2' 
                        src={EmailButton} 
                        alt="Telefon gomb" />
                    <h3 className='col-8 d-flex align-items-center'>
                        reani0421@gmail.com
                    </h3>
                </a>
                <h4 className='p-2 fw-bolder'>
                    Különleges alkalmakra, egyedi elképzelésekre. 
                    Várjuk szeretettel egyedi megrendeléseidet!
                </h4>
            </div>
            <div className='col-10 col-xl-5 p-2 hover-zoom'>
            <img 
                    className='img-fluid col-12 px-4' 
                    src={GalleryImage}
                    onClick={() => setIsIntroductionShown(false)}
                    alt="Gallery" />
                <div className='texty col-4'>
                    Galéria
                </div>
            </div>
        </div>   
        ) : (
        <>
            <RanoutGallery />
            <div className='col-12 py-2 d-flex justify-content-center'>
                <button 
                    type="submit" 
                    className="back-button" 
                    onClick={() => setIsIntroductionShown(true)}>
                    <span><i className="fa-solid fa-arrow-left"></i></span>
                </button>
            </div>
        </>
        
        )}
    </div>
)
}

export default GalleryNav

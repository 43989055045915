import React from 'react'


/**
 * Registration komponens
 * 
 * A Registration funkció egy React komponens, amely egy gombot és egy 
 * párbeszédpanelt jelenít meg a felhasználó regisztrációjához. 
 * Amikor a gombra kattintanak, a párbeszédpanel megjelenik.
 * 
 * @returns {JSX.Element} - Registration komponens
 * TODO
 */
function Registration() {
  return (
    <>
      <button 
        type="button" 
        className="btn btn-outline-primary px-3" 
        data-bs-toggle="modal" 
        data-bs-target="#register">
        <i className="fa-solid fa-user-plus pe-2"></i>
        Regisztráció
      </button>
      <div 
        className="modal fade" 
        id="register" 
        tabIndex="-1" 
        aria-labelledby="registerLabel" 
        aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 
                className="modal-title fs-5" 
                id="registerLabel">
                Regisztráció
              </h1>
              <button 
                type="button" 
                className="btn-close" 
                data-bs-dismiss="modal" 
                aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              ...
            </div>
            <div className="modal-footer">
              <button 
                type="button" 
                className="btn btn-secondary" 
                data-bs-dismiss="modal">
                Bezárás
              </button>
              <button 
                type="button" 
                className="btn btn-primary">
                Küldés
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Registration

import React from "react"
import Gif from "../images/loading.gif"

/**
 * LoadingGif komponens
 * 
 * A LoadingGif funkció egy JSX elemet ad vissza, amely egy betöltési GIF 
 * képet jelenít meg.
 * 
 * @returns {JSX.Element} - LoadingGif komponens
 */
export function LoadingGif() {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <img 
                className="img-fluid col-3" 
                src={Gif}
                alt="loading gif"/>
        </div>
    )
}
import React from 'react'
import NewProducts01 from '../../images/newproducts01.jpg'
import NewProducts02 from '../../images/newproducts02.jpg'
import NewProducts03 from '../../images/newproducts03.jpg'


/**
 * Carousel03 komponens
 * 
 * Carousel03 egy funkcionális komponens React környezetben, amely egy carousel 
 * szakaszt jelenít meg három képpel és azokhoz tartozó címekkel. 
 * A komponens importálja a képeket meghatározott fájl elérési útvonalakról, 
 * és sorba rendezve megjeleníti őket a címekkel alattuk. 
 * A képeket CSS animációval animálják.
 * 
 * @date 2024. 01. 31. - 16:00:32
 * @returns {JSX.Element} - Carousel03 komponens
 */
function Carousel03() {
  return (
    <section className='carousel3'>
      <div className='container pt-3'>
        <div className='col-12 row d-flex justify-content-center text-center'>
          <h2 className='blue text col-6 col-md-4 py-1 py-md-2 border 
                        border-primary rounded-pill'>
            Újdonságok:
          </h2>
        </div>
        <div className='row d-flex justify-content-evenly'>
          {renderImageWithTitle('Díszített gerbera', NewProducts01, 1)}
          {renderImageWithTitle('Valentin napi minikaspó', NewProducts02, 2)}
          {renderImageWithTitle('Ballagási virággömb', NewProducts03, 3)}
        </div>
      </div>
    </section>
  );
}

/**
 * Képet jelenít meg a címével és az animációs osztályokkal együtt.
 * 
 * @param {string} title - A kép címe
 * @param {string} imagePath - A kép fájl elérési útja.
 * @param {number} delay - Az animáció késése.
 * @returns {JSX.Element} - A kép a címével és az animációs osztályokkal.
 */
function renderImageWithTitle(title, imagePath, delay) {
  return (
    <div 
        className='pt-md-1 col-5 col-md-4 d-flex align-items-center 
              justify-content-center animate-speed row px-4 pt-0 
              animate__animated animate__zoomIn' 
        style={{ animationDelay: `${delay}s` }}>
        <img 
          className='img-fluid pt-0 mt-md-3 col-10 mx-2 px-0 border borderColor 
                      rounded-5' 
          src={imagePath} 
          alt={title} />
      <h5 className='fs-3 text textShadow col-12 text-center py-2'>{title}</h5>
    </div>
  );
}

export default Carousel03

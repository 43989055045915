import React, { useState } from 'react'
import Paper from '@mui/material/Paper'
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterTabs from './FilterTabs';
import Filters from './Filters';
import MenuIcon from '@mui/icons-material/Menu';
import ProductsNav from '../ProductsMenu/ProductsNav';


/**
 * ProductFilter komponens
 * 
 * A ProductsFilter komponens egy szűrőszakaszt jelenít meg a termékek listázó 
 * oldalon. Tartalmaz egy menügombot az oldalsó panel megnyitásához a 
 * navigációhoz, szűrési opciókat, és egy gombot egy további szűrőkért 
 * szolgáló oldalsó panel megnyitásához.
 * 
 * @returns {JSX.Element} - ProductFilter komponens
 */
function ProductsFilter() {
  const [isDrawerOpen1, setIsDrawerOpen1] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleMenuButtonClick = () => {
    setIsDrawerOpen(true);
  };

  const handleFilterButtonClick = () => {
    setIsDrawerOpen1(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setIsDrawerOpen1(false);
  };

  return (
    <Paper elevation={7} sx={{ ms: 0, py: 2 }}>
      <div className="row text-center">
        <div className="col-2 d-flex d-xxl-none px-4 px-5 ms-2 ms-sm-0 
                      justify-content-center align-items-center">
          <Button 
            variant="outlined" 
            aria-label="cart" 
            edge="start" 
            onClick={handleMenuButtonClick}>
            <MenuIcon />
          </Button>
          <Drawer 
            anchor="left" 
            open={isDrawerOpen} 
            onClose={handleDrawerClose}>
            <Box 
              p={2} 
              width="300px" 
              textAlign="left" 
              sx={{ overflowX: 'hidden' }} 
              role="presentation">
              <ProductsNav />
            </Box>
          </Drawer>
        </div>
        <div className="col-6 col-sm-7 col-md-8 col-xxl-9">
          <FilterTabs />
        </div>
        <div className="col-1 col-xl-2 d-flex align-items-center 
                      justify-content-start">
          <Button 
            variant="outlined" 
            aria-label="cart" 
            edge="start" 
            onClick={handleFilterButtonClick}>
            <div className="row m-0 px-0 d-flex align-items-center">
              <div className="col-12 col-xl-3 py-1">
                <FilterListIcon sx={{ fontSize: 26 }} />
              </div>
              <div className="d-none d-xl-block col-8 px-1">Szürők:</div>
            </div>
          </Button>
          <Drawer 
            anchor="right" 
            open={isDrawerOpen1} 
            onClose={handleDrawerClose}>
            <Box p={2} width="250px" textAlign="center" role="presentation">
              <Filters />
            </Box>
          </Drawer>
        </div>
      </div>
    </Paper>
  );
}

export default ProductsFilter

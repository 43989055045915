import React from 'react'
import Logo from '../../images/logo2.png'
import GroupPic from '../../images/introduction.png'


/**
 * Carousel02 komponens
 * 
 * Carousel02 egy funkcionális komponens React környezetben. 
 * JSX kódot ad vissza, amely egy carousel szakaszt ábrázol két oszlopban. 
 * Az első oszlop egy képet tartalmaz, a második oszlop pedig egy logót, 
 * fejlécet és bekezdést.
 * 
 * @date 2024. 01. 31. - 15:56:43
 * @returns {JSX.Element} - Carousel02 komponens
 */
function Carousel02() {
  return (
    <section className='carousel2'>
      <div className='container pt-3'>
        <div className='row'>
          <div 
            className='px-4 d-none d-lg-flex col-6 align-items-center
              justify-content-center animate-speed
              animate__animated animate__zoomIn animate__delay-1s'>
              <img 
                className='img-fluid col-10 px-2' 
                src={GroupPic} 
                alt="group products"
              /> 
          </div>
          <div 
            className='px-5 px-lg-0 pt-3 pt-xl-5 col-12 col-lg-6 row 
                      glass-effect text-center'>
              <div className="col-12 col-lg-4">
                <img 
                  className="img-fluid col-3 col-lg-12 none" 
                  src={Logo} 
                  alt="logo"
                />
              </div>
              <div className="col-12 col-lg-8">
                <h1>Virágtündér Műhelye</h1>
                <p className="blue text-center">Örökvirágok szaténból</p>
              </div>
              <div className="col-12 row">
                  <h4 className="ps-4 blue text-center fs-5 fs-xl-4 text">
                    Egyedi kézműves szatén szalagvirágok műhelye
                  </h4>
                  <h5 className="pb-5 fs-6 fs-xl-4 text mellow">
                  A Virágtündér műhelye kézműves örökvirágai szirmoként, gondos
                  kézimunkával készülnek, így garantálva az egyedi és időtálló 
                  ajándékot minden alkalomra. A természetes hatású színekkel 
                  gazdagíthatja otthona dekorációját, és ezzel 
                  megteremtve az egyedi és meghitt atmoszférát. A Virágtündér 
                  műhelyének termékei így tökéletes választást jelentenek mind 
                  azok számára, akik szeretnének maradandó emléket alkotni.
                  </h5>
                </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Carousel02

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { removeFromCart } from '../../redux/actions/cartActions'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import logo from '../../images/logo2.png'
import empty from '../../images/empty_cart.svg'


/**
 * Cart komponens
 * 
 * A Cart komponens egy React komponens, amely a bevásárlókosárban található 
 * tételeket jeleníti meg. Redux-ot használ a tárolt kosár tételeinek 
 * eléréséhez, és a useSelector hook-ot alkalmazza a kosár tételeinek 
 * lekéréséhez. Emellett a useDispatch hook-ot is használja a removeFromCart 
 * művelet végrehajtásához, amikor a felhasználó az egyes tételek melletti 
 * eltávolítás gombra kattint. A komponens kiszámolja a kosárban lévő tételek 
 * teljes árát, majd az alsó részen megjeleníti.
 * 
 * @returns {JSX.Element} - Cart komponens
 */
function Cart() {
  const cartItems = useSelector(state => state.cartItems);
  const dispatch = useDispatch();
  const calculateTotal = () => {
    return cartItems.reduce(
      (total, item) => total + item.piece * item.price, 0);
  };

  return (
    <div className='cart-container'>
      <div className='logo-container'>
        <img className='img-fluid col-8 p-4' src={logo} alt="logo" />
        <Divider sx={{"&::before, &::after": {
              borderColor: "#8c4e94",
              },
            }} >
            <Chip 
              variant="outlined" 
              label="Kosár" 
              sx={{
                fontSize: '1.5rem', 
                padding: '1rem', 
                color: '#5b6fee',
                fontWeight: 'semibold',
                borderColor: '#8c4e94;'
              }}
            />
        </Divider>
      </div>
      <div className='products-container'>
        {cartItems.length === 0 ? (
          <div className='d-flex align-items-center'>
            <div>
            <img 
              className='img-fluid col-12 p-4 ' 
              src={empty} 
              alt="empty cart" 
            />
            <p className='blue col-12'>A kosár üres</p>
            </div>
          </div>
        ) : (
          <Typography variant='h6' component={'div'} py={3}>
            {cartItems.map(item => (
                <div className="row" key={item.id}>
                <div className='col-5 p-2'>  
                  <img 
                    className='img-fluid col-12' 
                    src={item.src} 
                    alt={item.name}
                  />
                </div>
                <div className='col-6 p-2 d-flex align-items-center blue'>
                  <p className='text fs-6 mellow mb-0'>
                    {item.name}
                  </p>   
                </div>
                <div className='col-3 blue text fs-5'>
                  <p>{item.piece} db</p>
                </div>
                <div className='col-5 blue text fs-5'>
                  <p>{item.piece * item.price} Ft</p>
                </div>
                <div className='col-3'>
                  <button 
                    type="button" 
                    className="btn btn-outline-danger ms-2" 
                    onClick={() => dispatch(removeFromCart(item.id))}
                  >
                    X
                  </button>
                </div>               
              </div>
            ))}     
          </Typography>
        )}
      </div>
      <div className="total-container">
        <Divider sx={{"&::before, &::after": {
            borderColor: "#8c4e94",
              },
            }} >
          <Chip 
            variant="outlined" 
            label="Végösszeg:" 
            sx={{
              fontSize: '1.2rem', 
              padding: '1rem', 
              color: '#8c4e94',
              fontWeight: 'bold',
              borderColor: '#8c4e94'
            }}
          />
        </Divider>
        <h3 className='text-center pt-2 pe-2 blue'>{calculateTotal()} Ft</h3>
      </div>
    </div>
  );
}

export default Cart;

const initialState = {
    cartItems: getCartItemsFromLocalStorage(),
    lastUpdate: getLastUpdateFromLocalStorage(),
};


const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_TO_CART':
            const newItem = action.payload;

            if (newItem.piece <= 0) {
            return state;
            }

            const existingItem = state.cartItems.find(
                (item) => item.id === newItem.id
            );

            if (existingItem) {
                const updatedPiece =
                existingItem.piece + newItem.piece <= existingItem.max
                ? existingItem.piece + newItem.piece
                : existingItem.max;

            const updatedCartItems = state.cartItems.map((item) =>
            item.id === existingItem.id ? {
                ...item, piece: updatedPiece } : item
            );

            saveCartItemsToLocalStorage(updatedCartItems);

            return {
                ...state,
                cartItems: updatedCartItems,
                lastUpdate: new Date().getTime(), 
            };
            } else {
            const updatedPiece = newItem.piece <= newItem.max 
                ? newItem.piece : newItem.max;

            const updatedCartItems = [...state.cartItems,
                { ...newItem, piece: updatedPiece }];

            saveCartItemsToLocalStorage(updatedCartItems);

            return {
                ...state,
                cartItems: updatedCartItems,
                lastUpdate: new Date().getTime(), 
            };
        }

        case 'REMOVE_FROM_CART':
            
            const updatedCartItems = state.cartItems.filter(
            (item) => item.id !== action.payload
            );

        saveCartItemsToLocalStorage(updatedCartItems);

        return {
            ...state,
            cartItems: updatedCartItems,
            lastUpdate: new Date().getTime(), 
            };
            
        default:
            if (shouldClearCart(state.lastUpdate)) {
            
            clearCart();
            return {
                ...state,
                cartItems: [],
            };
            }
        return state;
    }
};

export default cartReducer;

function getCartItemsFromLocalStorage() {
    const storedCartItems = localStorage.getItem('cartItems');
    return storedCartItems ? JSON.parse(storedCartItems) : [];
}

function saveCartItemsToLocalStorage(cartItems) {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
}

function getLastUpdateFromLocalStorage() {
    const storedLastUpdate = localStorage.getItem('lastUpdate');
    return storedLastUpdate ? parseInt(storedLastUpdate, 10) : 0;
}

function saveLastUpdateToLocalStorage() {
    localStorage.setItem('lastUpdate', new Date().getTime().toString());
}

function shouldClearCart(lastUpdate) {
    const currentTime = new Date().getTime();
    const oneHourInMillis = 60 * 60 * 1000; 
    return currentTime - lastUpdate >= oneHourInMillis;
}

function clearCart() {
    localStorage.removeItem('cartItems');
    localStorage.removeItem('lastUpdate');
}


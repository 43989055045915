import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store from './redux/store'
import Nav from './components/01-Nav/Nav'
import Introduction from './components/02-Introduction/Introduction';
import ProductsMenu from './components/03-Products/ProductsMenu/ProductsMenu';
import GalleryNav from './components/04-Gallery/GalleryNav';






function App() {
  return (
    <Provider store={store}>
        <div className="App">
            <Nav />
            <Introduction />
              <section className='container-fluid row m-0 px-0 pb-5'>
                <ProductsMenu />
              </section>
              <section className='container-fluid row m-0 px-0 pb-5'>
                <GalleryNav />
              </section>
        </div>
    </Provider>
      
  );
}

export default App;

import React from 'react'
import { Divider, Chip } from '@mui/material'
import logo from '../../../images/logo2.png'
import { RibbonContainer,  Ribbon } from "react-ribbons";
import { DiscountHandler } from '../../DiscountHandler';

/* The `ProductDescription` component is rendering the description and
details of a product. It includes the product name, ID, logo image,
price, product description, and various attributes such as height,
length, width, weight, and material. */
function ProductDescription(props) {
    return (
        <div 
            className='col-11 col-md-5 col-xxl-4 px-3 text-start row order-1 
                    order-md-2 d-flex align-items-start ms-3 ms-md-0'>
            <div className='col-8 p-2 mt-4'>
                <h3 className='blue m-0'>{props.name}</h3>
                <p className='fs-6 ps-4 pt-2 text'>
                Azonosító: {props.productId}
                </p>
            </div>
            <div className='col-4 p-2 mt-4 '>
                <img 
                className='img-fluid navbar-title logoImage me-2 ' 
                src={logo}
                alt='logo'/>
            </div>

            <RibbonContainer className='px-0 ms-5 pe-5'>
                <div className='col-12 m-0 termekleiras text-center '>
                    {(Number(props.isDiscount) ? (
                        <Ribbon
                            side="left"
                            type="edge"
                            size="large"
                            backgroundColor="#cc0000"
                            color="#ccffff"
                            fontFamily="sans"
                            withStripes={true}>
                            Akció %
                        </Ribbon>
                    ) : ("")) ||
                    (Number(props.isNew) ? (
                        <Ribbon
                            side="left"
                            type="edge"
                            size="large"
                            backgroundColor="#2B9720"
                            color="#ccffff"
                            fontFamily="sans"
                            withStripes={true}>
                            Új termék
                        </Ribbon>
                    ) : ("")) ||
                    (Number(props.isDiscontinued) ? (
                        <Ribbon
                            side="left"
                            type="edge"
                            size="large"
                            backgroundColor="#8c4e94"
                            color="#ccffff"
                            fontFamily="sans"
                            withStripes={true}>
                            Kifutó
                            </Ribbon>
                    ) : (""))}
                    <div className='py-1'>
                        <DiscountHandler
                            variableclass="discountHandler"
                            price={props.price}
                            discountPrice={props.discountPrice}
                            isDiscount={props.isDiscount}/>  
                    </div>
                </div>
            </RibbonContainer>
            <Divider sx={{"&::before, &::after": {
                borderColor: "#8c4e94",
                },
            }}>
                <Chip 
                    variant="outlined" 
                    label="Termék leírás"
                    sx={{
                    fontSize: '1.3rem', 
                    padding: '1rem', 
                    color: '#8c4e94',
                    borderColor: '#8c4e94;'
                    }}/>
            </Divider>
                <h5 className="blue mx-3 px-1 pt-3">
                    {props.description}
                </h5>
                <h5 className="blue mx-3 pb-3 px-1">
                    {props.description2}
                </h5>
                <Divider sx={{"&::before, &::after": {
                    borderColor: "#8c4e94",
                    },
                }}>
                    <Chip 
                        variant="outlined" 
                        label="Termékjellemzők"
                        sx={{
                        fontSize: '1.3rem', 
                        padding: '1rem', 
                        color: '#8c4e94',
                        borderColor: '#8c4e94;'
                        }}/>
                </Divider>
                <ul className='blue lh-base'>
                    <li className='p-2'>
                    <span className='mellow text fs-5'>
                        {props.featuresTitle}
                    </span>
                        {props.features}
                    </li>
                    <li className='p-2'>
                    <span className='mellow text fs-5'>
                        {props.featuresTitle2}
                    </span>
                        {props.features2}
                    </li>
                    <li className='p-2'>
                    <span className='mellow text fs-5'>
                        Kézzel készített gondosság:
                    </span> Minden egyes terméket lelkiismeretesen és 
                    szeretettel készítünk el, hogy egyedi és kifinomult 
                    terméket nyújtsunk Önnek.
                    </li>
                    <li className='p-2'>
                    <span className='mellow text fs-5'>
                        Könnyen karbantartható:
                    </span> Nem igényel öntözést vagy gondoskodást. Egyszerűen 
                    porolja le időről időre, és mindig újnak fog tűnni.
                    </li>
                </ul>
                
                
        </div>
    )
}

export default ProductDescription

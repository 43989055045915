import React from "react"
/**
 * DiscountHandler komponens
 * 
 * A DiscountHandler funkció egy JavaScript függvény, amely JSX kódot ad 
 * vissza. Arra szolgál, hogy megjelenítse a termék árát kedvezménnyel 
 * vagy anélkül. A funkció három paramétert vesz át: 
 * isDiscount (egy logikai érték, amely azt jelzi, van-e kedvezmény vagy sem), 
 * price (a termék eredeti ára) és discountPrice (a termék kedvezményes ára).
 * 
 * @param {boolean} isDiscount - Van-e kedvezmény vagy sem
 * @param {number} price - A termék ára
 * @param {number} discountPrice - Kedvezményes ár
 * @returns {JSX.Element} - DiscountHandler komponens
 */
export function DiscountHandler(props) {
    return (
        <div className={props.variableclass}>
            {Number(props.isDiscount) ? (
                <p className='blue pt-2 ps-2 mb-1'>
                    Ár:
                    <span 
                        className='ps-1 text-danger mellow
                            text-decoration-line-through'>
                        {props.price} 
                    </span> 
                    <span 
                        className='ps-2 pe-1 blue'>
                        {props.discountPrice}
                    </span>
                    Ft
                </p>
            ) : (
                <p className='pt-2 blue mb-1 me-3 pe-5'>
                    Ár: 
                    <span className="blue ps-2">
                        {props.price}
                    </span> Ft
                </p>
            )}
        </div>
    )
}
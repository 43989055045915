import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Divider from '@mui/material/Divider'
import { NavLink} from "react-router-dom";
import Search from './Search';



/**
 * ProductsNav komponens
 * 
 * A ProductsNav komponens egy navigációs menüt jelenít meg egy 
 * termékkatalógushoz. A Material-UI könyvtárból származó TreeView komponenst 
 * használja egy hierarchikus fastruktúra megjelenítéséhez. A fa minden elemét 
 * egy-egy termékkategóriát reprezentál. A komponens tartalmaz továbbá egy 
 * keresőmezőt, és a kiválasztott kategóriától függően a navigáció kezeléséhez 
 * a React Router NavLink komponenst használja.
*
* @returns {JSX.Element} - ProductsNav komponens
*/
function ProductsNav() {
    const location = useLocation();
    const [isProductPageActive, setIsProductPageActive] = useState(false);
    
    useEffect(() => {
        // Ellenőrizzük, hogy az aktuális útvonal a ProductSinglePage-e
        const isActive = location.pathname.startsWith('/product/');
        setIsProductPageActive(isActive);
    }, [location]);
    const style = {
        overflowY: "auto",
        overflowX: "hidden",
        minHeight: isProductPageActive ? 1100 : 800,
        flexGrow: 1,
        backgroundColor: "#fff",
        maxWidth: 400,
        "& .MuiTreeItem-label": {
        fontSize: 30,
        }
    };
    return (
        <>  
        <div className='p-2 d-flex justify-content-center '>
            <Search />
        </div>
        <Divider sx={{ bgcolor: "secondary.light" }} />
        <TreeView
        className='blue'
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={style}
        >
        <NavLink 
            to={`/`} 
            className={
            ({ isActive }) => "nav-link" + (isActive ? " active" : "")} end>
            <TreeItem nodeId="1" label="Összes termék:" sx={{py: 1}}/>
            <Divider sx={{ bgcolor: "secondary.light" }} />
        </NavLink>
    {/* ------------------------- Kübli ------------------------ */}
        <NavLink 
            to={`/sortBykubli`} 
            className={
            ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
            <TreeItem nodeId="2" label="Küblik" sx={{pb: 1, }}>
            <NavLink 
                to={`/sortByegyviragos`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="3" label="Egyvirágos küblik" />
            </NavLink>
            <NavLink 
                to={`/sortByharomviragos`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="4" label="Háromvirágos küblik" />
            </NavLink>
            </TreeItem>
            <Divider sx={{ bgcolor: "secondary.light" }} />
        </NavLink>
    {/* ------------------------- Rattan ------------------------- */}
        <NavLink 
            to={`/sortByrattan`} 
            className={
            ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
            <TreeItem nodeId="5" label="Rattan virágbox" sx={{pb: 1}}>
            <NavLink 
                to={`/sortByotviragos`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="6" label="5 virágos rattanbox" />
            </NavLink>
            <NavLink 
                to={`/sortBykilencviragos`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="7" label="9 virágos rattanbox" />
            </NavLink>
            </TreeItem>
            <Divider sx={{ bgcolor: "secondary.light" }} />
        </NavLink>
  {/* ------------------------- Szívbox ------------------------- */}
        <NavLink 
            to={`/sortByszivbox`} 
            className=
            {({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
            <TreeItem nodeId='8' label="Szívboxok" sx={{pb: 1}} />
            <Divider sx={{ bgcolor: "secondary.light" }} />
        </NavLink>
  {/* ------------------------- Körbox ------------------------- */}
        <NavLink 
            to={`/sortBykorbox`} 
            className=
            {({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
            <TreeItem nodeId='9' label="Körboxok" sx={{pb: 1}} />
            <Divider sx={{ bgcolor: "secondary.light" }} />
        </NavLink>
  {/* --------------------- Szálas virágok --------------------- */}
        <NavLink 
            to={`/sortByszalasvirag`} 
            className=
            {({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
            <TreeItem nodeId="10" label="Szálas virágok" sx={{pb: 1}}>
            <NavLink 
            to={`/sortByszalasrozsa`} 
            className=
            {({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
            <TreeItem nodeId="11" label="Rózsa" />
            </NavLink>
            <NavLink 
            to={`/sortByszalasgerbera`} 
            className=
            {({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="12" label="Gerbera" />
            </NavLink>
            <NavLink 
            to={`/sortBydiszitettgerbera`} 
            className=
            {({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="13" label="Díszített gerbera" />
            </NavLink>
            </TreeItem>
        </NavLink>
        <Divider sx={{ bgcolor: "secondary.light" }} />

    {/* ------------------------------- Kaspó ----------------------------- */}
        <NavLink 
            to={`/sortBykaspo`} 
            className={
            ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
            <TreeItem nodeId="14" label="Kaspós virágok" sx={{pb: 1}}>
            <NavLink 
                to={`/sortByminikaspo`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="15" label="Mini kaspó" />
            </NavLink>
            <NavLink 
                to={`/sortBykiskaspo`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="16" label="Kis kaspó" />
            </NavLink>
            <NavLink 
                to={`/sortBynagykaspo`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="17" label="Nagy kaspó" />   
            </NavLink>
            <NavLink 
                to={`/sortByhusvetitulipan`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="18" label="Húsvéti tulipán" />
            </NavLink>
            </TreeItem>
            <Divider sx={{ bgcolor: "secondary.light" }} />
        </NavLink>
    {/* -------------------------- Virágcsokrok -------------------------- */}
        <NavLink 
            to={`/sortBycsokrok`} 
            className={
            ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
            <TreeItem nodeId="19" label="Virágcsokrok" sx={{pb: 1}}>
            <NavLink 
                to={`/sortByballagocsokor`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="20" label="Ballagó csokor" />
            </NavLink>
            <NavLink 
                to={`/sortBysircsokor`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="21" label="Sírcsokor" />
            </NavLink>
            <NavLink 
                to={`/sortBykorcsokor`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="22" label="Körcsokor" />
            </NavLink>
            </TreeItem>
            <Divider sx={{ bgcolor: "secondary.light" }} />
        </NavLink>
    {/* --------------------------- Koszorúk ----------------------------- */}
        <NavLink 
            to={`/sortBykoszoru`} 
            className={
            ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
            <TreeItem nodeId='23' label="Koszorúk" sx={{pb: 1}} />
            <Divider sx={{ bgcolor: "secondary.light" }} />
        </NavLink>
    {/* ------------------------- Virágkosarak -------------------------- */}
        <NavLink 
            to={`/sortByviragkosar`} 
            className={
            ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
            <TreeItem nodeId='24' label="Virágkosarak" sx={{pb: 1}} >
            <NavLink 
                to={`/sortByballagokosar`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="25" label="Ballagó kosár" />
            </NavLink>
            <NavLink 
                to={`/sortByflorentinkosar`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="26" label="Florentin kosár" />
            </NavLink>
            </TreeItem>
            <Divider sx={{ bgcolor: "secondary.light" }} />
        </NavLink>
    {/* ---------------------------- Rózsagömb --------------------------- */}
        <NavLink 
            to={`/sortByrozsagomb`} 
            className={
            ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
            <TreeItem nodeId='27' label="Rózsagömb" sx={{pb: 1}} />
            <Divider sx={{ bgcolor: "secondary.light" }} />
        </NavLink>
    {/* ----------------------- Karácsonyi termékek ---------------------- */}
        <NavLink 
            to={`/sortBykaracsony`} 
            className={
            ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
            <TreeItem nodeId="28" label="Karácsonyi termékek" sx={{pb: 1}}>
            <NavLink 
                to={`/sortBymikulasvirag`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="29" label="Mikulásvirág" />
            </NavLink>
            <NavLink 
                to={`/sortBykopogtato`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="30" label="Kopogtatók" />
            </NavLink>
            <NavLink 
                to={`/sortByholdkopogtato`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="31" label="Holdkopogtatók" />
            </NavLink>
            <NavLink 
                to={`/sortByangyalka`} 
                className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "")}>
                <TreeItem nodeId="32" label="Angyalkák" />
            </NavLink>
        </TreeItem>
        <Divider sx={{ bgcolor: "secondary.light" }} />
        </NavLink>
        </TreeView>
    </>
    )
}

export default ProductsNav

import React from 'react';
import { NavLink } from "react-router-dom";
import { RibbonContainer, Ribbon } from "react-ribbons";
import { DiscountHandler } from "../DiscountHandler";
import { PieceChecker } from "../PieceChecker";


/**
 * ProductsSchema komponens
 * 
 * A ProductsSchema egy funkcionális React komponens, amely egy products tömböt
 * kap bemenetként. Végigmegy a products tömbön, és egy termékkártyák listáját 
 * jeleníti meg. Minden termékkártya tartalmazza a termék nevét, képét, árát, 
 * kedvezmény információt és darabszám információt. Emellett szalagokat is 
 * tartalmaz, amelyek jelzik, ha a termék akciós, új, vagy kifutó. A komponens 
 * különböző segítő komponenseket használ, mint például a DiscountHandler és a 
 * PieceChecker a kedvezmények és darabszám információ kezelésére. Továbbá, 
 * a NavLink-et használja a navigációhoz egy egyedi termék útvonalhoz.
 *
 * @param {{ products: array; }} products - Termékek listája
 * @returns {JSX.Element} - ProductSchema komponens
 */
function ProductsSchema({ products }) {
    return (
        <div className="col-12 row p-3 product-list">
            {/* Termék listázó*/}
            {products.map((product) => ( 
                <div 
                    className="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxl-2 
                                mellowHover" 
                    key={product.id}
                >

                    {/* Navigáció az single product útvonalra*/}
                    <NavLink
                        className="mellow"
                        to={"/product/" + product.id} 
                        underline="none">

                        {/*Szalag konténer */}
                        <RibbonContainer>
                        <div className="card mx-1 my-3 shadow">

                            {/* Akciós szalag vizsgáló*/}
                            {(Number(product.isDiscount) ? (
                                <Ribbon
                                    side="right"
                                    type="corner"
                                    size="large"
                                    backgroundColor="#cc0000"
                                    color="#ccffff"
                                    fontFamily="sans"
                                    withStripes={true}>
                                    Akció %
                                </Ribbon>
                            ) : ("")) ||

                            /* Új termék szalag vizsgáló*/
                            (Number(product.isNew) ? (
                                <Ribbon
                                    side="right"
                                    type="corner"
                                    size="large"
                                    backgroundColor="#2B9720"
                                    color="#ccffff"
                                    fontFamily="sans"
                                    withStripes={true}>
                                    Új termék
                                </Ribbon>
                            ) : ("")) ||

                            /* Kifutó termék szalag vizsgáló*/
                            (Number(product.isDiscontinued) ? (
                                <Ribbon
                                    side="right"
                                    type="corner"
                                    size="large"
                                    backgroundColor="#8c4e94"
                                    color="#ccffff"
                                    fontFamily="sans"
                                    withStripes={true}>
                                    Kifutó
                                </Ribbon>
                            ) : (""))}

                            {/* Termék neve*/}
                            <h5 
                                className="product-name ps-2 pe-5">
                                    {product.name}
                            </h5>
                            <div className="img-hover-zoom p-0">
                            {/* Termék képe*/}
                                <img 
                                    className="img-fluid"
                                    src={product.img} 
                                    alt={product.name}/>
                            </div>
                            {/* Termék információk*/}
                            <div 
                                className="row product-info">
                                {/* Termék ára*/}
                                <div 
                                    className="product-price ps-0 pe-0 pe-xxl-2
                                        col-7 col-sm-12 col-md-7 col-xxl-12"
                                >

                                    {/* Akciós ár vizsgáló*/}
                                    <DiscountHandler
                                        variableclass="p-1"
                                        price={product.price}
                                        discountPrice={product.discountPrice}
                                        isDiscount={product.isDiscount}/>  
                                </div>
                                {/* Termék darabszáma*/}
                                <div 
                                    className="col-5 col-sm-12 col-md-5 
                                        col-xxl-12 product-piece ps-0">
                                    <p className="mb-1">
                                    {PieceChecker(Number(product.piece))} 
                                    </p>
                                </div>
                            </div>
                        </div>
                        </RibbonContainer>
                    </NavLink>
                </div>
            ))}
        </div>
    );
}

export default ProductsSchema;
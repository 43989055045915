import React from 'react'
import Logo from '../../images/logo2.png'


/**
 * Carousel01 komponens
 * 
 * A Carousel01 funkció egy React komponens, amely egy carousel szekciót 
 * jelenít meg egy képpel és egy alcímmel. Importál egy logóképet, és azt 
 * használja forrásnak az img elemhez.
 * 
 * @date 2024. 01. 30. - 18:34:24
 * @returns {JSX.Element} - Carousel01 komponens
 */
function Carousel01() {
  return (
    <section className='carousel1'>
    <div className='container pt-3'>
      <div className='row d-flex justify-content-center align-items-center'>
        <div 
          className='mx-auto px-4 pb-5 pb-sm-3 pb-md-0 pt-5 col-11 col-md-9 
              col-lg-6 m-3 d-flex align-items-center animate-speed 
              animate__animated animate__zoomIn animate__delay-1s'>
          <img 
            className='img-fluid col-12 col-md-10 px-2' 
            src={Logo} 
            alt="logo"/> 
        </div>
        <div 
          className='px-4 pt-5 pt-xl-4 pt-xxl-3 col-12 col-md-9 col-xl-6 m-3 
            animate-speed animate__animated animate__zoomIn animate__delay-2s'>
            <p className="blue text-center ms-5 ms-lg-2 ms-xl-5 me-lg-5 me-xl-4 
                        subtitle fs-5 fs-lg-4 text pb-3 mt-5 ">
                Örökvirágok szaténból
            </p>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Carousel01

/**
 * PieceChecker komponens
 * 
 * A PieceChecker funkció egy piece nevű paramétert vesz át, és ellenőrzi annak
 * értékét. Ha a piece kisebb vagy egyenlő 0-val, akkor a "Rendelhető" stringet
 * adja vissza. Ha a piece nagyobb, mint 0, akkor egy olyan stringet ad vissza,
 * amely tartalmazza a piece értékét, utána pedig a "db készleten" stringet.
 * 
 * @param {number} piece
 * @returns {JSX.Element} - PieceChecker komponens
 */
export function PieceChecker(piece) { 
    if (piece <= 0) {
        let checker = "Rendelhető"
        return checker
        
    } else if (piece > 0 ) {
        let checker = `${piece} db készleten`
        return <div className="text-success">{checker}</div>
        
    }
}

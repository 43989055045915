import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ProductsFilter from '../ProductsFilter/ProductsFilter'
import { Breadcrumbs, Link, Typography, } from '@mui/material'
import { NavLink } from 'react-router-dom'
import ImageSection from './ImageSection'
import ProductDescription from './ProductDescription'
import OrderingSection from './OrderingSection'
import { LoadingGif } from '../../LoadingGif'
import { useParams, useNavigate } from 'react-router-dom'



/**
 * ProductSinglePage komponens
 * 
 * ProductSinglePage egy React komponens, amely megjeleníti egyetlen termék 
 * részleteit. A termékadatokat egy API végponttól lekéri az axios könyvtár 
 * és a useEffect hook segítségével. A lekért adatokat a singleProduct 
 * állapotváltozóban tárolja. A komponens különböző részeket renderel a 
 * termékoldalon, mint például az képrészlet, a termékleírás és a rendelési 
 * rész. Emellett egy betöltési ikont is tartalmaz, amíg az adatok lekérése 
 * folyamatban van. A komponens a React Router-t használja annak érdekében, 
 * hogy a vissza gombra kattintva visszanavigáljon az előző oldalra.
 * 
 * @returns {JSX.Element} - ProductSinglePage komponens
 */
function ProductSinglePage() {
  const [isFetchPending, setFetchPending] = useState(false);
  const [singleProduct, setSingleProduct] = useState([])
  const id = useParams();
  const navigate = useNavigate();
  
  
  useEffect(() => {
    setFetchPending(true);
    axios.get(`https://rt-teszt-hu.hu/api/product/${id.productId}`)
    .then(function (response) {
      console.log("res:", response.data);
      setSingleProduct(response.data);
      console.log(singleProduct)
      setFetchPending(false)
    })
    .catch(function (error) {
      console.log(error);
      
    })
    .finally(function () {
      setFetchPending(false)
    })
  }, [id.productId]);

  /**
   * Eltávolítja az ékezeteket és a szóközöket a karakterláncból.
   * @param {string} str - A bemeneti karakterlánc
   * @returns {string} - A karakterlánc ékezetek és szóközök nélkül.
   */
  function removeAccents(str) {
  return (
    str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, ""))
  }
  return (
    <nav className='col-12 col-xxl-10 products-nav m-0 p-0 shadow-lg'>
        <ProductsFilter/>
        <div className='row py-2 me-0 mt-1 
                ms-1 products-background'>
        {isFetchPending ? (
            <LoadingGif />
          ) : (
          <>
            <div className='row d-flex align-items-start'>
              <div className='col-6 mellow'>
                <Breadcrumbs 
                  aria-label="breadcrumb"
                  color="secondary.dark">
                  <NavLink 
                      to={"/"} 
                      className="mellow">
                        összes termék
                  </NavLink>
                  <NavLink 
                    to={`/sortBy${removeAccents(String(singleProduct.type1))}`} 
                    className="mellow">
                      {singleProduct.type1}
                  </NavLink>
                {singleProduct.type1 !== singleProduct.type2 && (
                  <NavLink 
                    to={`/sortBy${removeAccents(String(singleProduct.type2))}`} 
                    className="mellow">
                      {(singleProduct.type2)}
                  </NavLink>    
                )}
                  <Typography 
                    color="text.primary">
                      {singleProduct.name}
                  </Typography>
                </Breadcrumbs>
              </div>
              <div className='col-6 d-flex justify-content-end'>
                  <button 
                    type="submit" 
                    className="back-button" 
                    onClick={() => navigate(-1)}>
                    <span><i className="fa-solid fa-arrow-left"></i></span>
                  </button>
              </div>
            </div>
            <ImageSection 
              birthday={singleProduct.birthday}
              allsoulsday={singleProduct.allsoulsday}
              christmas={singleProduct.christmas}
              valentineday={singleProduct.valentineday}
              nameday={singleProduct.nameday}
              womensday={singleProduct.womensday}
              graduation={singleProduct.graduation}
              easter={singleProduct.easter}
              wedding={singleProduct.wedding}
              mothersday={singleProduct.mothersday}
              height={singleProduct.height}
              length={singleProduct.length}
              width={singleProduct.width}
              weight={singleProduct.weight}
              material={singleProduct.material}
              image1={singleProduct.img} 
              image2={singleProduct.img2} 
              image3={singleProduct.img3} 
              image4={singleProduct.img4}
            />
            <ProductDescription 
              name={singleProduct.name}
              price={singleProduct.price}
              discountPrice={singleProduct.discountPrice}
              isDiscount={singleProduct.isDiscount}
              isNew={singleProduct.isNew}
              isDiscontinued={singleProduct.isDiscontinued}
              productId={singleProduct.productId}
              description={singleProduct.description}
              description2={singleProduct.description2}
              featuresTitle={singleProduct.featuresTitle}
              features={singleProduct.features}
              featuresTitle2={singleProduct.featuresTitle2}
              features2={singleProduct.features2}
              />
            <OrderingSection 
              piece={singleProduct.piece}
              name={singleProduct.name}
              img={singleProduct.img}
              price={singleProduct.currentPrice}
              id={singleProduct.productId}
            />
          </>
        )}
        </div>
    </nav>
  );
}

export default ProductSinglePage

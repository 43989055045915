import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Chip } from '@mui/material'

/* The `IsGoodForAnEvent` function is a React component that
renders a `Chip` component based on the value of the
`canBeGiven` prop. If `canBeGiven` is equal to 1, it renders a
green `Chip` with a check icon and the label specified by the
`event` prop. If `canBeGiven` is not equal to 1, it renders a
red `Chip` with a clear icon and the label specified by the
`event` prop. */
export function IsGoodForAnEvent(props) {
    return (
        <>
            {props.canBeGiven==1 ? (
                <Chip 
                    label={props.event}
                    variant="outlined"
                    size="medium"
                    color="success"
                    sx={{mr: 1, my: 1}}
                    icon={<CheckIcon />}/>
            ) : ("")} 
        </>
    )
}
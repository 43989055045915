import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@mui/material/InputAdornment";
import axios from 'axios'
import { NavLink, useNavigate } from 'react-router-dom';

function Search() {
  const [products, setProducts] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    axios.get('https://rt-teszt-hu.hu/api/productnames' )
    .then(function (response) {
        console.log(response.data);
        setProducts(response.data);
        })
    .catch(function (error) {
        console.log(error);})
  }, []);
  const testHandler = (event, newValue) => {
    products.map((product) => {
      if(newValue === product.name) {
        navigate("/product/" + product.id);
        setSelectedOption([])
      }
      
    })
  }

  return (
    <Stack spacing={2} sx={{ width: 250 }}>
    <Autocomplete
      freeSolo
      disableClearable
      options={products.map((product) => (product.name))}
      value={selectedOption}
      onChange={testHandler}
      renderInput={(params) => (
        <TextField
        {...params}
        label="Keresés"
        multiline={true}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            endAdornment: (
              <NavLink to={`/productSinglePage/` + params.id } >
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </NavLink>
              )
          }}
        />
      )}
    />
    
  </Stack>
  )
}

export default Search

import React, { useState, useReducer } from 'react'
import { PieceChecker } from '../../PieceChecker'
import { useDispatch } from 'react-redux'
import { addToCart } from '../../../redux/actions/cartActions';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


function OrderingSection(props) {
    let [cartCount, setCartCount] = useState(0)
    const dispatch = useDispatch();
    const initialState = Number(1);
    
    const reducer = (state, action,) => {
    switch(action) {
        case 'increment':
            return state + 1
        case 'decrement':
            return state - 1
        case 'reset':
            return initialState
        default:
            return state
    }
}
    
    let [count, dispach] = useReducer(reducer, initialState)
    
    //Snackbar
    const [isSuccessSnackbarOpen,  setSuccessSnackbarOpen] = useState(false);
    const [isErrorSnackbarOpen,  setErrorSnackbarOpen] = useState(false);
    
    const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

        setSuccessSnackbarOpen(false);
        setErrorSnackbarOpen(false);
    };
    
    

    const handleAddToCart = () => {
        if (cartCount < Number(props.piece)) {
            let newCartCount = cartCount + Number(count);
            setCartCount(newCartCount);
            dispatch(addToCart({
                id: props.id,
                name: props.name,
                price: Number(props.price),
                src: props.img,
                piece: (Number(count)),
                max: Number(props.piece),
                cartCount: newCartCount 
            }));
            setSuccessSnackbarOpen(true);
            
        } else {
            setErrorSnackbarOpen(true);
        }
        console.log(cartCount);
    };
    
    
    return (
        <div className='col-12 col-xxl-3 ms-3 d-flex d-xxl-block row order-3'>
            <div className='col-11 col-md-6 col-xxl-12 pb-2'>
                <h3 className='p-3 mt-3 col-12 text-center order-main'>
                    <i className="fa-solid fa-truck-fast pe-3"></i>
                    Szállítási módok: 
                </h3>
                <div className="accordion" id='shipping-methods'>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button 
                                className="accordion-button" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#collapseOne" 
                                aria-expanded="false" 
                                aria-controls="collapseOne">
                                MPL házhozszállítás
                            </button>
                        </h2>
                        <div 
                            id="collapseOne" 
                            className="accordion-collapse collapse" 
                            data-bs-parent="#shipping-methods">
                            <div className="accordion-body">
                                MPL házhozszállítás: 1500 FT
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button 
                                className="accordion-button collapsed" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#collapseTwo" 
                                aria-expanded="false" 
                                aria-controls="collapseTwo">
                                Foxpoxt csomagautómata
                            </button>
                        </h2>
                    <div 
                        id="collapseTwo" 
                        className="accordion-collapse collapse" 
                        data-bs-parent="#shipping-methods">
                        <div className="accordion-body">
                            Foxpoxt csomagautómata: 1200 Ft
                        </div>
                    </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button 
                                className="accordion-button collapsed" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#collapseThree" 
                                aria-expanded="false" 
                                aria-controls="collapseThree">
                                Személyes átvét
                            </button>
                        </h2>
                        <div 
                            id="collapseThree" 
                            className="accordion-collapse collapse" 
                            data-bs-parent="#shipping-methods">
                            <div className="accordion-body">
                                Személyes átvét: Szeged, József Attila sgt. 
                                77/E 9. Csengő
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-11 col-md-6 col-xxl-12 pb-2'>
                <h3 className='p-3 mt-3 mt-xxl-5 col-12 text-center 
                                order-main'>
                    <i className="fa-solid fa-credit-card pe-3"></i>
                    Fizetési módok:
                </h3>
                <div className="accordion" id='payment-methods'>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button 
                                className="accordion-button" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#paymentCollapseOne" 
                                aria-expanded="false" 
                                aria-controls="paymentCollapseOne">
                                SimplePay átutalás
                            </button>
                        </h2>
                        <div 
                            id="paymentCollapseOne" 
                            className="accordion-collapse collapse" 
                            data-bs-parent="#payment-methods">
                            <div className="accordion-body">
                                SimplePay
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button 
                                className="accordion-button collapsed" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#paymentCollapseTwo" 
                                aria-expanded="false" 
                                aria-controls="paymentCollapseTwo">
                                Személyes átvétel esetén: Kézpénz
                            </button>
                        </h2>
                        <div 
                            id="paymentCollapseTwo" 
                            className="accordion-collapse collapse" 
                            data-bs-parent="#payment-methods">
                            <div className="accordion-body">
                            Személyes átvétel esetén: Kézpénz
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Number(props.piece) === 0 ? (
                <>
                    <div className="col-12 pt-3 text-center">
                        <h5 style={{color: "green"}}>
                            {PieceChecker(Number(props.piece))} 
                        </h5>
                    </div>
                    <div className='col-12 col-md-6 col-xxl-12 pt-3 pb-3 d-flex 
                            justify-content-center justify-content-md-start 
                            justify-content-xxl-center'>
                        <button type="submit" className="contact-button" >
                            <span>Kapcsolat</span>
                        </button>
                    </div>
                </>
            ) : (
            <>
            <div className='row pt-3 d-flex align-items-center'>
                <div className="col-6 col-xxl-5 ps-5 ps-xxl-3 text-center">
                    <h5 style={{color: "green"}}>
                        {PieceChecker(Number(props.piece))} 
                    </h5>
                </div>
                <div className="col-6 col-xxl-7 py-2 d-flex 
                            justify-content-center">
                    <div className="row">
                        <button 
                            type="button" 
                            className="input-number-decrement"
                            onClick={
                                (count === Number(1)) ? null : 
                                () => dispach('decrement')}
                        >
                            –
                        </button>
                        <input 
                            className="input-number" 
                            name="piece" 
                            value={ count } 
                            type="text" 
                            min={1}
                            max={ props.piece } 
                            disabled={(props.piece === 0) ? "disabled" : "" }/>
                        <button 
                            type="button" 
                            className="input-number-increment" 
                            onClick={
                                ((count === Number(props.piece )) 
                                || (props.piece === 0 )) ? 
                                null : () => dispach('increment')}
                            >
                            +
                        </button>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-6 col-xxl-12 pt-3 pb-3 pt-xxl-4 
                        d-flex justify-content-center justify-content-md-end
                        justify-content-xxl-center'>
                <button 
                    type="button" 
                    className="cart-button"
                    onClick={() => handleAddToCart()}
                >
                    <span>Kosárba</span>
                </button>
            </div>
            <div className='col-12 col-md-6 col-xxl-12 pt-3 pb-3 d-flex 
                            justify-content-center justify-content-md-start 
                            justify-content-xxl-center'>
                <button type="submit" className="buy-button" >
                    <span>Megveszem</span>
                </button>
            </div>
            <Snackbar
                open={isSuccessSnackbarOpen}
                autoHideDuration={2500}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={handleSnackbarClose}
                >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity="success"
                    sx={{ paddingX: "3rem", paddingY: "0.8rem" }}
                >
                    Hozzáadva a kosárhoz!
                </MuiAlert>
                </Snackbar>
                
                <Snackbar
                    open={isErrorSnackbarOpen}
                    autoHideDuration={2500}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    onClose={handleSnackbarClose}
                    >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity="error"
                        sx={{ paddingX: "3rem", paddingY: "0.8rem" }}
                    >
                        Elérte a maximális darabszámot a készletből!
                    </MuiAlert>
                </Snackbar>
            </>
            )}
        </div>
    )
}

export default OrderingSection

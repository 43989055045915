import React from 'react';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom'
import PercentIcon from '@mui/icons-material/Percent';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Button } from '@mui/material'


/**
 * FilterTabs komponens
 * 
 * A FilterTabs komponens egy olyan szűrőgombok készletet jelenít meg, amely 
 * segít az elemek rendezésében. Minden gomb a Material-UI könyvtár Button 
 * komponensét használja, ikont, valamint feliratot tartalmaz. A gombokat a 
 * react-router-dom könyvtár NavLink komponensekbe csomagolja, ami lehetővé 
 * teszi a különböző útvonalakra való navigációt.
 * 
 * @date 2024. 01. 31. - 19:53:00
 * @returns {JSX.Element} - FilterTabs komponens
 */
function FilterTabs() {
  return (
    <div className="d-none d-sm-flex align-items-center
                    justify-content-end">
      <div className='p-2 d-none d-sm-flex align-items-center
                    justify-content-end'>
        <Box sx={{ width: '100%' }}>
        <Button variant="outlined" edge='start' sx={{ mx: 0.5, p: 0 }}>
          <NavLink 
              to={"/sortByDiscount"} 
              className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "") }>
              <div className='row m-0 px-0 d-flex align-items-center'>
                <div className='col-12 col-xl-2 py-2'>
                  <PercentIcon sx={{ fontSize: 26 }} />
                </div>
                <div className='d-none d-xl-block col-10 px-1'>
                Akciós termékek
                </div>
              </div>
          </NavLink>
        </Button>
        <Button 
          variant="outlined"
          edge='start'
          sx={{mx: 0.5, p: 0}}>
          <NavLink 
              to={"/sortByNew"} 
              className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "") }>
              <div className='row m-0 px-0 d-flex align-items-center'>
                <div className='col-12 col-xl-2 py-2'>
                  <FiberNewIcon sx={{ fontSize: 26 }} />
                </div>
                <div className='d-none d-xl-block col-10 px-1'>
                Újdonságok
                </div>
              </div>
          </NavLink>
        </Button>
        <Button variant="outlined" edge='start' sx={{ mx: 0.5, p: 0 }}>
          <NavLink 
              to={"/sortByIncrease"} 
              className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "") }>
              <div className='row m-0 px-0 d-flex align-items-center'>
                <div className='col-12 col-xl-2 py-2'>
                  <ArrowUpwardIcon sx={{ fontSize: 26 }} />
                </div>
                <div className='d-none d-xl-block col-10 px-1'>
                Olcsóbb elől
                </div>
              </div>   
          </NavLink>
        </Button>
        <Button variant="outlined" edge='start' sx={{ mx: 0.5, p: 0 }}>
          <NavLink 
              to={"/sortByDecrease"} 
              className={
                ({ isActive }) => "nav-link" + (isActive ? " active" : "") }>
              <div className='row m-0 px-0 d-flex align-items-center'>
                <div className='col-12 col-xl-2 py-2'>
                  <ArrowDownwardIcon sx={{ fontSize: 26 }} />
                </div>
                <div className='d-none d-xl-block col-10 px-1'>
                Drágább elől
                </div>
              </div>  
          </NavLink>
        </Button>
        </Box>
      </div>
    </div>
  );
}

export default FilterTabs

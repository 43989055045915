import React from 'react'
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import GalleryList from './GalleryList'
import { 
    Stack, 
    ImageList, 
    ImageListItem, 
    Box, 
    ImageListItemBar, 
} from '@mui/material'

/**
 * CustomOrdersGallery komponens
 * 
 * Egy képgalériát jelenít meg a react-photoswipe-gallery és a @mui/material 
 * könyvtárak felhasználásával. Minden kép egy mozaik elrendezésben jelenik 
 * meg az @mui/material ImageList komponens segítségével. 
 * A képre kattintva egy nagyobb verzió nyílik meg egy fényképes ablakban.
 *
 * @returns {JSX.Element} - CustomOrdersGallery komponens
 */
function RanoutGallery() {
    return (
        <Stack spacing={2} sx={{ marginTop: 3 }}>
            <Box sx={{ mx: 25}}>
                
                <Gallery 
                    withCaption
                    options={{
                        captionStyles: {
                            fontSize: '46px', // Betűméret
                            color: 'red', // Betűszín
                        },
                    }}>
                    <ImageList variant="masonry" cols={4} gap={35}>
                        
                    {GalleryList.map((img) => (
                        <Item
                            key={img.id}
                            caption={img.name}
                            original={img.url}
                            width={img.width}
                            height={img.height}
                        >
                        {({ ref, open }) => (
                            <ImageListItem key={img.id}>
                                <img
                                    style={{
                                        cursor: 'pointer',
                                        maxWidth: '100%',
                                        height: 'auto',
                                    }}
                                    ref={ref}
                                    onClick={open}
                                    src={img.url}
                                    alt={img.name}
                                    loading="lazy"
                                />
                                <ImageListItemBar 
                                    title={`${img.id}. ${img.name}`} />
                            </ImageListItem>
                        )}
                        </Item>
                    ))}
                    </ImageList>
                    
                </Gallery>
            </Box>
        </Stack>
    );
}


export default RanoutGallery

import React from 'react'

/**
 * Login komponens
 * 
 * A Login funkció egy React komponens, amely egy bejelentkezés gombot jelenít 
 * meg. Amikor a gombra kattintanak, egy párbeszédpanel jelenik meg a 
 * bejelentkezési űrlap számára. 
 * 
 * @returns {JSX.Element} - Login komponens
 * TODO
 */
function Login() {
  return (
    <>
      <button 
        type="button" 
        className="btn btn-outline-success px-3" 
        data-bs-toggle="modal" 
        data-bs-target="#login">
        <i className="fa-solid fa-user-check pe-2"></i>
        Bejelentkezés
      </button>
      <div 
        className="modal fade" 
        id="login" 
        tabIndex="-1" 
        aria-labelledby="loginLabel" 
        aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 
                className="modal-title fs-5" 
                id="loginLabel"> 
                Bejelentkezés
              </h1>
              <button 
                type="button" 
                className="btn-close" 
                data-bs-dismiss="modal" 
                aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              ...
            </div>
            <div className="modal-footer">
              <button 
                type="button" 
                className="btn btn-secondary" 
                data-bs-dismiss="modal">
                Bezárás
              </button>
              <button 
                type="button" 
                className="btn btn-primary">
                Küldés
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login

import React from 'react'
import Paper from '@mui/material/Paper';
import { BrowserRouter, Routes, Route,} from "react-router-dom";
import Products from '../Products';
import SortByPrimaryType from '../SortByPrimaryType';
import SortBySecondaryType from '../SortBySecondaryType'
import ProductSinglePage from '../SingleProducts/ProductSinglePage';
import SortByDiscount from '../SortByDiscount';
import SortByIncrease from '../SortByIncrease';
import SortByNew from '../SortByNew';
import SortByDecrease from '../SortByDecrease';
import SortByColor from '../sortByColor';
import SortByEvent from '../sortByEvent';
import ProductsNav from './ProductsNav';


/**
 * ProductsMenu komponens
 * 
 * A ProductsMenu komponens felelős a termékek oldalának navigációs menüjének 
 * és útválasztási logikájának megjelenítéséért. A react-router-dom könyvtárban
 * lévő BrowserRouter komponenst használja az útválasztás kezeléséhez. 
 * A ProductsMenu komponensen belül található egy aside elem, amely a 
 * navigációs menüt (ProductsNav) tartalmazza, egy Paper komponenssel ágyazva, 
 * amely a @mui/material könyvtárból származik.
 * 
 * @returns {JSX.Element} - ProductsMenu komponens
 */
function ProductsMenu() {
  return (
    <BrowserRouter>
    <aside id="sidebar" className='d-none d-xxl-block col-2 m-0 p-0'>
      <Paper elevation={7} sx={{ ms: 0, px: 2, pt: 2 }}>
        <ProductsNav />
      </Paper>
    </aside>

    <Routes>
        <Route path="/" element={<Products />} />
        <Route 
          path="/sortBykubli" 
          element={<SortByPrimaryType type="kübli"/>} />
        <Route 
          path="/sortByegyviragos" 
          element={<SortBySecondaryType type="egyviragos"/>} />
        <Route 
          path="/sortByharomviragos" 
          element={<SortBySecondaryType type="háromvirágos"/>} />
        <Route 
          path="/sortByrattan" 
          element={<SortByPrimaryType type="rattan"/>} />
        <Route 
          path="/sortByotviragos" 
          element={<SortBySecondaryType type="ötvirágos"/>} />
        <Route 
          path="/sortBykilencviragos" 
          element={<SortBySecondaryType type="kilencvirágos"/>} />
        <Route 
          path="/sortByszivbox" 
          element={<SortByPrimaryType type="szivbox"/>} />
        <Route 
          path="/sortBykorbox" 
          element={<SortByPrimaryType type="körbox"/>} />
        <Route 
          path="/sortByszalasvirag" 
          element={<SortByPrimaryType type="szálasvirág"/>} />
        <Route 
          path="/sortByszalasrozsa" 
          element={<SortBySecondaryType type="szálasrózsa"/>} />
        <Route 
          path="/sortByszalasgerbera" 
          element={<SortBySecondaryType type="szálasgerbera"/>} />
        <Route 
          path="/sortBydiszitettgerbera" 
          element={<SortBySecondaryType type="díszítettgerbera"/>} />
        <Route 
          path="/sortBykaspo" 
          element={<SortByPrimaryType type="kaspó"/>} />
        <Route 
          path="/sortByminikaspo" 
          element={<SortBySecondaryType type="minikaspó"/>} />
        <Route 
          path="/sortBykiskaspo" 
          element={<SortBySecondaryType type="kiskaspó"/>} />
        <Route 
          path="/sortBynagykaspo" 
          element={<SortBySecondaryType type="nagykaspó"/>} />
        <Route 
          path="/sortByhusvetitulipan" 
          element={<SortBySecondaryType type="húsvétitulipán"/>} />
        <Route 
          path="/sortBycsokrok" 
          element={<SortByPrimaryType type="csokrok"/>} />
        <Route 
          path="/sortByballagocsokor" 
          element={<SortBySecondaryType type="ballagócsokor"/>} />
        <Route 
          path="/sortBysircsokor" 
          element={<SortBySecondaryType type="sírcsokor"/>} />
        <Route 
          path="/sortBykorcsokor" 
          element={<SortBySecondaryType type="körcsokor"/>} />
        <Route 
          path="/sortBykoszoru" 
          element={<SortByPrimaryType type="koszorú"/>} />
        <Route 
          path="/sortByviragkosar" 
          element={<SortByPrimaryType type="virágkosár"/>} />
        <Route 
          path="/sortByballagokosar" 
          element={<SortBySecondaryType type="ballagókosár"/>} />
        <Route 
          path="/sortByflorentinkosar" 
          element={<SortBySecondaryType type="florentinkosár"/>} />
        <Route 
          path="/sortByrozsagomb" 
          element={<SortBySecondaryType type="rózsagömb"/>} />
        <Route 
          path="/sortBykaracsony" 
          element={<SortByPrimaryType type="karácsony"/>} />
        <Route 
          path="/sortBymikulasvirag" 
          element={<SortBySecondaryType type="mikulásvirág"/>} />
        <Route 
          path="/sortBykopogtato" 
          element={<SortBySecondaryType type="kopogtató"/>} />
        <Route 
          path="/sortByholdkopogtato" 
          element={<SortBySecondaryType type="holdkopogtató"/>} />
        <Route 
          path="/sortByangyalka" 
          element={<SortBySecondaryType type="angyalka"/>} />
        <Route 
          path="/sortByColor/:productColor" 
          element={<SortByColor/>} />
        <Route 
          path="/sortByEvent/:productEvent" 
          element={<SortByEvent/>} />
        <Route 
          path="/productSinglePage" 
          element={<ProductSinglePage />} />
        <Route 
          path="/sortByDiscount" 
          element={<SortByDiscount/>} />
        <Route 
          path="/sortByNew" 
          element={<SortByNew/>} />
        <Route 
          path="/sortByIncrease" 
          element={<SortByIncrease/>} /> 
        <Route 
          path="/sortByDecrease" 
          element={<SortByDecrease/>} /> 
        <Route path="/product/:productId" element={<ProductSinglePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default ProductsMenu

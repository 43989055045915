import React, { useState, useEffect, useRef } from 'react'
import { Paper } from '@mui/material';
import { IsGoodForAnEvent } from './IsGoodForAnEvent';
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'



function ImageSection(props) {
    let [primaryImage, setPrimaryImage] = useState(props.image1)
    const primaryImageRef = useRef(primaryImage);
    
    useEffect(() => {
        setPrimaryImage((newimg) => primaryImageRef.current = newimg);
    
    }, [primaryImage]);

    return (
        <div className='col-11 col-md-6 col-xxl-4 m-4 p-2 d-block 
                        justify-content-center order-2 order-md-1'>
            <Paper elevation={12}>
            <Gallery>
                <div className='col-12 p-2'>
                <Item
                    original={ primaryImage }
                    width="800"
                    height="600"   
                >
                    {({ ref, open }) => (
                    <img
                        style={{ cursor: 'pointer' }}
                        ref={ref}
                        className='col-12 p-2'
                        onClick={open}
                        src={primaryImage} 
                        alt="image1" />
                    )}
                </Item>
                </div>
                <div className='row mx-1 px-2 pb-4'>
                    <div className='col-3 p-1'>
                    <Item
                        original={ props.image1 }
                        width="800"
                        height="600">
                        {({ ref }) => (
                        <img 
                            ref={ref}
                            onClick={() => setPrimaryImage(props.image1)}
                            className='col-12 p-2 img-fluid hov' 
                            src={props.image1} 
                            alt="image1" />
                        )}
                    </Item>
                    </div>
                    <div className='col-3 p-1'>
                        <Item
                            original={ props.image2 }
                            width="800"
                            height="600">
                        {({ ref }) => (
                        <img
                            ref={ref}
                            onClick={() => setPrimaryImage(props.image2) }
                            className='col-12 p-2 img-fluid hov' 
                            src={props.image2} 
                            alt="image2" />
                        )}
                        </Item>
                    </div>
                    <div className='col-3 p-1'>
                        <Item
                            original={ props.image3 }
                            width="800"
                            height="600">
                        {({ ref }) => (
                        <img
                            ref={ref}
                            onClick={() => setPrimaryImage(props.image3) }
                            className='col-12 p-2 img-fluid hov' 
                            src={props.image3} 
                            alt="image3" />
                        )}
                        </Item>
                    </div>
                    <div className='col-3 p-1'>
                        <Item
                            original={ props.image4 }
                            width="800"
                            height="600">
                        {({ ref }) => (
                        <img
                            ref={ref}
                            onClick={() => setPrimaryImage(props.image4) }
                            className='col-12 p-2 img-fluid hov' 
                            src={props.image4} 
                            alt="image4" />
                        )}
                        </Item>
                    </div>
                </div>
            </Gallery>
            </Paper>
            <h5 className="mellow pt-3 m-3 px-2 text-center">
                    Szeretettel ajánljuk: 
            </h5>
            <div className='col-12 ms-3 d-block'>
                <IsGoodForAnEvent 
                    event="Szülinap" 
                    canBeGiven={props.birthday} />
                <IsGoodForAnEvent 
                    event="Névnap" 
                    canBeGiven={props.nameday} />
                <IsGoodForAnEvent 
                    event="Nők napja" 
                    canBeGiven={props.womensday} />
                <IsGoodForAnEvent 
                    event="Anyák napja" 
                    canBeGiven={props.mothersday} />
                <IsGoodForAnEvent 
                    event="Valentin nap" 
                    canBeGiven={props.valentineday} />
                <IsGoodForAnEvent 
                    event="Ballagás" 
                    canBeGiven={props.graduation} />
                <IsGoodForAnEvent 
                    event="Esküvő" 
                    canBeGiven={props.wedding} />
                <IsGoodForAnEvent 
                    event="Húsvét" 
                    canBeGiven={props.easter} />
                <IsGoodForAnEvent 
                    event="Karácsony" 
                    canBeGiven={props.christmas} />
                <IsGoodForAnEvent 
                    event="Halottak napja" 
                    canBeGiven={props.allsoulsday} />
            </div>
            <div className="col-12 pt-4">
                <div className="termekleiras text-center py-3 m-2">
                    <div className="row borbott text-left mx-2 p-2">
                        <div className="col-12 pb-2 fs-4 text">
                            <i className="fas fa-cut pe-2"></i> 
                            Kézműves termék
                        </div>
                    </div>
                    <div className="row borbott text-left mx-2 p-2">
                        <div className="col-5">
                            <i className="fas fa-long-arrow-alt-up pe-2"></i> 
                            Magasság:
                        </div>
                        <div className="col-7">{props.height} cm,</div>
                    </div>
                    <div className="row borbott text-left mx-2 p-2">
                        <div className="col-5">
                        <i className="fas fa-long-arrow-alt-right pe-2"></i> 
                            Hosszúság:
                        </div>
                        <div className="col-7">{props.length} cm,</div>
                    </div>
                    <div className="row borbott text-left mx-2 p-2">
                        <div className="col-5">
                            <i className="fas fa-arrows-alt-h  pe-2"></i> 
                            Szélesség:
                        </div>
                        <div className="col-7">{props.width} cm,</div>
                    </div>
                    <div className="row borbott text-left mx-2 p-2">
                        <div className="col-5">
                            <i className="fas fa-weight-hanging pe-2"></i> 
                            Súly:
                        </div>
                        <div className="col-7">{props.weight} dkg,</div>
                    </div>
                    <div className="row borbott text-left mx-2 p-2">
                        <div className="col-5">
                            <i className="fab fa-buffer pe-2"></i> 
                            Anyaga:
                        </div>
                        <div className="col-7">
                            {props.material}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageSection

